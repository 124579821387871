import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { bgLineData } from "./func/FixedChartData";
import { create7DayLabel, createDayLabel, create30DayLabel } from "./func/LabelCreator";
import WatchChart from './WatchChart'
import { fetchWatchEventStats,fetchEventStatsByType } from "../../services/watches.service";
import { handleFilterDate } from "../../actions/actionsDateTime";
import { DEVICE_COLOR_DICT } from "../../config/style.config";
import { DEVICE_TYPE } from "../../dto/devices";
import { CPM_DEFAULT_FILTER, isHideConfinedSpace, isHideDangerZone, isHideSmartCamera, isHideSmartLock } from "../../config";
const datapoints = [0, 14 ,6, 8, 13, 24, 46, 30,32, 70 ,50, 60,40, 100,80, 90, 60, 70, 75, 45,30, 60, 20 ,10];
const zoomIndexDict = {
    1:1,
    2:2,
    3:3,
    4:4,
    5:6,
    6:8,
    7:12,
    8:24
}
const zoomIndexLabelDict = createDayLabel()
const sevenDayLabels = create7DayLabel()
const tyDayLabels = create30DayLabel()
const labelsbyType = {
    "Today": zoomIndexLabelDict,
    "7 Days": sevenDayLabels,
    "30 Days": tyDayLabels,
}
function createDataSetObj(name, dataArr, pointBgColor, pointBorderColor, toSub, xLabels){
    return {
        label: name,
        data: dataArr,
        toSub: toSub,
        xLabels: xLabels,
        pointRadius:5,
        pointBackgroundColor: pointBgColor,
        borderColor: pointBorderColor,
        fill: false,
        tension: 0.4,
        showLine:false
      }
}
function createTempData(data,zoomLevel, range){
    //   console.log(data)
        const newChartDataObj = {}
        newChartDataObj.data  = data.map(({name, dataArr, pointBgColor, pointBorderColor, toSub, xLabels}) => {
            return createDataSetObj(name, dataArr, pointBgColor, pointBorderColor, toSub, xLabels)
        })
        //console.log(newChartDataObj.data[1])
        newChartDataObj.data.unshift(bgLineData(newChartDataObj.data))
        const DATA_COUNT = data[0].dataArr.length;
        newChartDataObj.labels = labelsbyType[range][zoomLevel];

        //data need for chart
        return newChartDataObj
    }
function consolideArray(zoomLevel,datapoints = [], dateVal, customDates){
    let newArray =[]
    let count =0
    let sum =0
    let zoomVal = zoomIndexDict[zoomLevel]
    let idx = 0
    // if(dateVal === '30 Days' && zoomVal === 2) zoomVal = 4
    if(customDates){
        for(let num of datapoints){
            if(count === 0){
                const [date,month,year] = new Date(num).toLocaleDateString().split('/')
                sum = `${date}/${month}`
            }
            count++
            if(count === zoomVal || idx === datapoints.length - 1){
                if(zoomVal !== 1){
                    const [date,month,year] = new Date(num).toLocaleDateString().split('/')
                    sum += ` - ${date}/${month}`
                }
                newArray.push(sum)
                count=0
                sum=0
            }
            idx++
        }
        return newArray
    }
    for(let num of datapoints){
        if(count< zoomVal){
            sum+=num
        }
        count++
        if(count=== zoomVal || idx === datapoints.length - 1){
            newArray.push(sum)
            count=0
            sum=0
        }
        idx++
    }
    return newArray||[]
}


const newData =(zoomLevel, sortedByTimeObj, dateVal)=> {
    const SOSArr = consolideArray(zoomLevel,sortedByTimeObj.SOS, dateVal)
    const BOArr = consolideArray(zoomLevel,sortedByTimeObj.BO, dateVal)
    const FALLArr = consolideArray(zoomLevel,sortedByTimeObj.FALL, dateVal)
    const HRArr = consolideArray(zoomLevel,sortedByTimeObj.HR, dateVal)
    const SPACEArr = consolideArray(zoomLevel,sortedByTimeObj.SPACE, dateVal)
    const labels = consolideArray(zoomLevel,sortedByTimeObj.timeLabels, dateVal, true)
    const SOSSub = SOSArr.map((num, i) => {
        if(num === 0) return 0
        let added = BOArr[i] + FALLArr[i] + HRArr[i] + SPACEArr[i]
        SOSArr[i] += added
        return added
    })
    const BOSub = BOArr.map((num, i) => {
        if(num === 0) return 0
        let added = FALLArr[i] + HRArr[i] + SPACEArr[i]
        BOArr[i] += added
        return added
    })
    const FALLSub = FALLArr.map((num, i) => {
        if(num === 0) return 0
        let added = HRArr[i] + SPACEArr[i]
        FALLArr[i] += added
        return added
    })
    const HRSub = HRArr.map((num, i) => {
        if(num === 0) return 0
        let added = SPACEArr[i]
        HRArr[i] += added
        return  added
    })
    const SPACESub = SPACEArr.map((num, i) => {
        if(num === 0) return 0
        let added = 0
        SPACEArr[i] += added
        return  added
    })
    return [
        {
            name: 'Smart Watch',
            dataArr: SOSArr,
            toSub: SOSSub,
            xLabels: labels,
            pointBgColor: DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH],
            pointBorderColor: DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH],
        },
        {
            name: 'Smart Lock',
            dataArr: BOArr,
            toSub: BOSub,
            xLabels: labels,
            pointBgColor: DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK],
            pointBorderColor: DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK],
        },
        {
            name: 'Danger Zone',
            dataArr: FALLArr,
            xLabels: labels,
            toSub: FALLSub,
            pointBgColor: DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE],
            pointBorderColor: DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE],
        },
        {
            name: 'AI Camera',
            dataArr: HRArr,
            toSub: HRSub,
            xLabels: labels,
            pointBgColor: DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
            pointBorderColor: DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
        },
        {
            name: 'Confined Space',
            dataArr: SPACEArr,
            toSub: SPACESub,
            xLabels: labels,
            pointBgColor: DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE],
            pointBorderColor: DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE],
        },
    ]
}
//
function Component(props){
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = (searchParams.get('date') || CPM_DEFAULT_FILTER)
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const [ChartData, setChartData] = useState(undefined)
    const [sortedData, setSortedData] = useState({})
    const [maxValue, setMaxValue] = useState(0)
    const [zoomLevel,setZoomLevel]=useState(1)
    const [customRange, setCustomRange] = useState(undefined)
    // useEffect(() => {
    //     setChartData(createTempData(newData(zoomLevel),zoomLevel))
    // },[zoomLevel])
    useEffect(() => {
        fetchChartData()
        let timer = undefined
        if(props.isHome) timer = setInterval(() => fetchChartData(), 30000)
        return () => {
            if(timer) clearInterval(timer)
        }
    },[filterDate,filterFrom,filterTo])
    async function fetchChartData(){
        const keys = {
            "Today": 24,
            "7 Days": 7,
            "30 Days": 30,
        }
        const today = new Date(new Date().setHours(0,0,0,0))
        today.setDate(today.getDate() + 1)
        //console.log({today})
        let timeRangeArr = []
        const sortedDataObj = {}
        let filter = handleFilterDate(filterDate, filterFrom, filterTo)
        const localRange = filterTo && filterFrom ? (new Date(filterTo) - new Date(filterFrom))  / (1000 * 60 * 60 * 24) : 0
        //console.log('localRange', localRange)
        setCustomRange(localRange)
        for(let i = (localRange == 0 ? keys[filterDate] : localRange); i >= 0; i--){
            const temp = localRange == 0 ? new Date(today) : new Date(filterTo)
            if(filterFrom && filterTo)temp.setDate(temp.getDate() - i)
            if(filterDate === 'Today' && localRange == 0)temp.setHours(today.getHours() - i)
            // if(filterDate === '7 Days')temp.setHours(today.getHours() - (12 * (i)))
            if(filterDate === '30 Days' || filterDate === '7 Days')temp.setDate(today.getDate() - (1 * (i)))
            timeRangeArr.push(temp)
        }
        // console.log('locaRange result', timeRangeArr)
        // const SosRes = await fetchEventStatsByType(props.token.token, {'time':filter,"device":"WATCH", "projectId": props.control.projectId}, {limit:999})
        // const HrRes = await fetchEventStatsByType(props.token.token, {'time':filter,"device":"CAMERA", "projectId": props.control.projectId}, {limit:999})
        // const BosRes = await fetchEventStatsByType(props.token.token, {'time':filter,"device":"LOCK", "projectId": props.control.projectId}, {limit:999})
        // const FallRes = await fetchEventStatsByType(props.token.token, {'time':filter,"device":"DANGER_ZONE", "projectId": props.control.projectId}, {limit:999})
        // const SpaceRes = await fetchEventStatsByType(props.token.token, {'time':filter,"device":"CONFINED_SPACE", "projectId": props.control.projectId}, {limit:999})
        const test = await fetchEventStatsByType(props.token.token, {'time':filter,"type":{"$nin":["LOCK_LOCK", "LOCK_UNLOCK"]}, "projectId":props.control.projectId}, {limit:999})
        const data = test.result
        sortedDataObj.SOS = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        sortedDataObj.HR = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        sortedDataObj.BO = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        sortedDataObj.FALL = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        sortedDataObj.SPACE = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        //console.log({timeRangeArr})
        //console.log({SosRes})
        data?.forEach((obj) => {
            const eventTime = new Date(obj.time)
            // console.log(eventTime)
            timeRangeArr?.forEach((time, timeIdx) => {
                // console.log(new Date(time))
                // console.log(timeRangeArr[timeIdx])
                let nextTimeSlot = timeRangeArr[timeIdx + 1]
                if(!nextTimeSlot){
                    nextTimeSlot = new Date(time)
                    nextTimeSlot.setDate(new Date(nextTimeSlot).getDate() + 1)
                }
                // if(timeIdx === (filterFrom && filterTo ? keys[filterDate] : localRange)) return;
                // if(obj.type.split('_')[0] === 'LOCK' && obj.type.split('_')[1] !== 'LOCK' && obj.type.split('_')[1] !== 'UNLOCK') console.log({type: obj.type, eventTime, time, nextTimeSlot, timeIdx})
                if(eventTime >= time && eventTime <= nextTimeSlot){
                    if(obj.type.split('_')[0] === 'WATCH')sortedDataObj.SOS[timeIdx]++
                    if(obj.type.split('_')[0] === 'CAMERA')sortedDataObj.HR[timeIdx]++
                    if(obj.type.split('_')[0] === 'LOCK' && obj.type.split('_')[1] !== 'LOCK' && obj.type.split('_')[1] !== 'UNLOCK'){
                        sortedDataObj.BO[timeIdx]++}
                    if(obj.type.split('_')[0] === 'DANGER')sortedDataObj.FALL[timeIdx]++
                    if(obj.type.split('_')[0] === 'CS')sortedDataObj.SPACE[timeIdx]++
                }
            })
        })
        //console.log({sortedDataObj})
        sortedDataObj.timeLabels = timeRangeArr
        setSortedData(sortedDataObj)
    }
    useEffect(() => {
        const data = createTempData(newData(zoomLevel, sortedData , filterDate),zoomLevel, filterDate)
        if(customRange || (filterDate === '30 Days')|| (filterDate === '7 Days')) data.labels = null
        setChartData(data)
        let localMax = 0
        data.data?.forEach((obj) => {
            if(obj.label !== '') localMax = Math.max(...obj.data, localMax)
        })
        setMaxValue(localMax)
        // console.log(localMax)
    },[zoomLevel, sortedData])
    const handleWheelEvent=(e)=>{
        let delta = e?.deltaY>0?1:-1
        let newLevel = zoomLevel+delta
        // if(filterDate === '7 Days') return setZoomLevel(newLevel<=1 ? 1 : newLevel>=2 ? 2 : newLevel)
        // if(filterDate === '30 Days') return setZoomLevel(newLevel<=1?1:newLevel>=2?2:newLevel)
        setZoomLevel(newLevel <= 1 ? 1 : newLevel >=8 ? 8 : newLevel)
    }
    return(
        <div key={`chart-${zoomLevel}`} className="text-white w-full" onWheel={handleWheelEvent}>
            <div className="flex flex-row justify-between items-center pb-[8px]" style={{borderBottom:'.5px solid #aaa'}}>
                <div className="text-[16px] font-[300]">Trends</div>
            </div>
            {ChartData && <WatchChart chartDataObj={ChartData} showLegend={false} maxValue={maxValue}/>}

            <div className="flex flex-row items-center justify-center p-[24px] gap-[24px]">
                {isHideSmartCamera||<div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] bg-[#0057FF] flex rounded-full" style={{background:DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA]}}/>
                    AI Camera
                </div>}
                <div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] flex rounded-full" style={{background:DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH]}}/>
                    Smart Watch
                </div>
                {isHideSmartLock||<div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] bg-[#F47CFF] flex rounded-full" style={{background:DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK]}}/>
                    Smart Lock
                </div>}
                {isHideDangerZone||<div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] bg-[#29FFD9] flex rounded-full" style={{background:DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE ]}}/>
                    Danger Zone
                </div>}
                {isHideConfinedSpace||<div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] bg-[#29FFD9] flex rounded-full" style={{background:DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE]}}/>
                    Confined Space
                </div>}
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)